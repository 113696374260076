<template>
    <div class='grid' v-if='company_id && dd_companies'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco dei teams facenti capo a {{companyName}}</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='name' header='Nome' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>


                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        /></template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                            <router-link :to='gotoId(slotProps.data.id + "/skills")' :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-indigo-600 hover:text-indigo-400 pointer ml-1'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Featured Skills'
                                    :icon="['fas', 'thumbtack']"
                                    size='2x'
                                />
                            </router-link>
                            <router-link :to='gotoId(slotProps.data.id + "/paths")' :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-orange-500 hover:text-pink-500 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Percorsi'
                                    :icon="['fas', 'route']"
                                    size='2x'
                                />
                            </router-link>
                            <router-link :to='gotoId(slotProps.data.id + "/users")' :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-green-500 hover:text-green-700 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Utenti'
                                    :icon="['fas', 'user-friends']"
                                    size='2x'
                                />
                            </router-link>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/CompanyTeams');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';

import ListHeader from '../../../components/4books/ListHeader';
import dd_companies from '../../../mixins/dd_companies';
const commonStore = createNamespacedHelpers('Common');

export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (company) return company.name;
            }
            return "Team " + this.company_id;
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        setPageBreadcrumbs(){
            this.setExtraBreadcrumbs([
                { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                { label: 'Teams' }
            ]);
        },
        refreshList() {
            this.setPageBreadcrumbs();
            this.fetchList(this.company_id).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    props: {
        company_id: {
            default: 0,
            type: Number
        }
    },

    watch: {
        company_id() {
            this.refreshList();
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        }
    },
    mixins: [
        Notifications,
        Navigations,
        dd_companies
    ],
};
</script>
